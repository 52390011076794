"use client";
import { Suspense, lazy } from "react";
import { RxHamburgerMenu } from "react-icons/rx";

const Deals = lazy(() => import("@/components/phonesBanner"));
const Brand = lazy(() => import("@/components/Brand"));
const Provider = lazy(() => import("@/components/Provider"));
const Footer = lazy(() => import("@/components/footer"));
const Navbar = lazy(() => import("@/components/navbar"));
const MobileBanner = lazy(() => import("@/components/mobilesBanner"));
const Banner = lazy(() => import("@/components/banner"));
const Browse = lazy(() => import("@/components/browse"));
const QuickAccess = lazy(() => import("@/components/quickaccess"));
const Header = lazy(() => import("@/components/header"));
const TabFilter = lazy(() => import("@/components/tabfilter"));
const FAQ = lazy(() => import("@/components/faq"));
const Ratings = lazy(() => import("@/components/ratings"));
const BlogList = lazy(() => import("@/components/bloglist"));
const Blog = lazy(() => import("@/components/Blog"));

export default function Widget({
  pageResults,
  page2Results,
  DealResults,
  brandResults,
  mobiles,
  providerResults,
}) {
  const filteredMobiles = mobiles.filter(
    (mobile) => mobile.slug && mobile.slug.includes("apple")
  );
  const filteredDeals = DealResults && DealResults.length > 0 ? DealResults.filter((mobile) => {
    // Check if the mobile slug includes "apple"
    const appleFilter = mobile.slug && mobile.slug.includes("melon") || mobile.slug.includes("me-you");
  
    // Check if  providerResults is available and apply the additional filter
    if (providerResults && providerResults.length > 0) {
      return appleFilter && mobile.provider.slug.includes(providerResults[0].slug);
    }
  
    // If providerResults is not available, only apply the apple filter
    return appleFilter;
  }) : [];
  
  

  
  // console.log(filteredDeals)
  return (
    <div className="bg-white">
      <div className="sticky top-0 z-50">
        <Banner />
        <Navbar burgerIcon={<RxHamburgerMenu color="black" size="1.5em" />} />
      </div>

      <Suspense fallback={<div className="h-[1100px]"></div>}>
        <MobileBanner
          commonPhones={DealResults}
          Banner={pageResults.mobileBanner}
          providerResults={providerResults}
        />
        <TabFilter providerResults={providerResults} Deal={filteredDeals} filterDatas={page2Results.filterData} />
        <QuickAccess />
        <Ratings reviewsData={pageResults.reviews || []} />
        {/* <Deals cardData={DealResults} brands={brandResults} /> */}
        <BlogList bloglist={pageResults.blogs || []} />
        <FAQ faqData={pageResults.faq} />
        <Provider providersData={pageResults.Provider} />
        <Browse />
        <Blog blog={pageResults.blog} />
        <Footer />
      </Suspense>
    </div>
  );
}
